import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import timeAgo from 'time-ago';
import Typography from '@material-ui/core/Typography';
import currency from 'format-currency';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  table: {
    // maxWidth: "50%",
  },
});

export default withStyles(styles)(({balances, savings, debt, classes}) => {
  return (
    <Paper elevation={3} className={classes.root}>
      <Typography style={{margin: 20}} variant="h2">${currency(savings - debt)}</Typography>
      <Typography style={{color: 'grey'}} variant="subtitle1">{currency(savings)} - {currency(debt)} owed</Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {balances.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
              <code style={{color: 'grey'}}>{row.mask}</code> &nbsp; {row.name}
              </TableCell>
              <TableCell style={ row.type === 'credit' ? {fontStyle: 'italic'} : {}} align="right">{ row.type === 'credit' ? '-' : ''}{currency(row.balance)}</TableCell>
              <TableCell align="right">{timeAgo.ago(row.updated, true)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
})