import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from '@material-ui/core/LinearProgress';

class PasswordDialog extends React.Component {
  state = {
    password: ''
  }

  render() {
    const {errors, onPassword, open, pending} = this.props;
    const { password } = this.state;
    return (
      <Dialog
        open={open}
      >
        <DialogTitle>Password</DialogTitle>
        <DialogContent>
          <p>Please enter your password to access this site.</p>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            value={password}
            error={errors}
            onChange={(e) => this.setState({password: e.target.value})}
          />
          { pending && <LinearProgress /> }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onPassword(this.state.password)} color="primary" disabled={pending}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default PasswordDialog;