import React, { Component } from "react";
import "./App.css";
import Balances from "./Balances";
import logo from "./dollar.png";
import Button from "@material-ui/core/Button";
import PasswordDialog from './PasswordDialog';

const BALANCES_ENDPOINT = process.env.REACT_APP_BALANCES_ENDPOINT;

const byTypeAndAmount = (a, b) => {
  if (a.type === "credit" && b.type !== "credit") {
    return 1;
  } else if (a.type !== "credit" && b.type === "credit") {
    return -1;
  } else {
    return b.balance - a.balance;
  }
};

class App extends Component {
  state = {
    apiKey: localStorage.getItem('apiKey'),
    balances: [],
    balance: 0,
    savings: 0,
    debt: 0
  };

  componentDidMount() {
    const apiKey = localStorage.getItem('apiKey');
    if (apiKey) {
      this.updateBalances()
    }
  }

  async updateBalances(apiKey) {
    try {
      const data = await this.getBalances(this.state.apiKey);
      this.setState({
        balances: data.balances,
        balance: data.balance,
        savings: data.savings,
        debt: data.debt,
        passwordError: null,
        tryingPassword: false
      });
    } catch(e) {
      this.setState({apiKey: null});
      localStorage.removeItem('apiKey');
    }
  }

  getBalances = async (apiKey) => {
    const data = await fetch(BALANCES_ENDPOINT, {
      headers: {
        "x-api-key": apiKey
      }
    }).then(r => r.json());
    data.balances = data.balances.sort(byTypeAndAmount);
    return data;
  }

  logout = () => {
    localStorage.removeItem("apiKey");
    this.setState({ apiKey: null });
  };

  handlePassword = async (password) => {
    this.setState({tryingPassword: true});
    try {
      const data = await this.getBalances(password);
      this.setState({
        balances: data.balances,
        balance: data.balance,
        savings: data.savings,
        debt: data.debt,
        passwordError: null,
        apiKey: password,
        tryingPassword: false
      });
      localStorage.setItem('apiKey', password);
    } catch(e) {
      this.setState({
        passwordError: true,
        tryingPassword: false
      });
    }
  }

  render() {
    const { apiKey, balances, savings, debt } = this.state;

    return (
      <div className="App">
        <img style={{ marginTop: 20 }} src={logo} />
        {apiKey && (
          <React.Fragment>
            <Balances balances={balances} savings={savings} debt={debt} />
            <p>Updated every 30 minutes</p>
            <Button color="primary" variant="contained" onClick={this.logout}>
              Logout
            </Button>
          </React.Fragment>
        )}
        <PasswordDialog errors={this.state.passwordError} onPassword={this.handlePassword} open={!apiKey} pending={this.state.tryingPassword} />
      </div>
    );
  }
}

export default App;
